.backtop {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  background: #FF6E30;
  color: #fff;
  font-size: 20px;
  margin: 20px 0px 0px 0px;
}

@media (max-width: 1200px) {
  .backtop {
    position: fixed;
    left: 6px;
    bottom: 36px;
  }
}