.navbar {
  /* background-color: #1DA57A; */
  /* padding-left: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; */
  position: fixed;
  bottom: 2em;
  /* left: 1em; */
  right: 0.2em;
  border-radius: 10px;
}

@media (min-width: 1200px) {
  .navbar {
    right: 3em;
  }
}
