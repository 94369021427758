.fill {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden
}

.fill img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%
}

.page {
    background-color: 'cyan';
    border: "2px solid black"
}

@media only screen and (max-width : 1024px) {

}
@primary-color: #FF6E30;