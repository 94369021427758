.choice_container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.choice_row {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  margin: 5px 0;
  border-bottom: 1px solid #969db1;
  flex-flow: row wrap;
}

.choice_input {
  margin: 0 5px;
  flex: 1;
}

.choice_image {
  margin-right: 5px;
}
