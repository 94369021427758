.navbar {
   z-index: 1000 ;
  }
  
  @media (min-width: 990px) {
    .menu {
      display: none;
    }
  }
  
  .logo {
    height: 32px;
    margin-left: 1rem;
  }

  .navbar-button {
    left: 2px;
    top: 1px;
  }
@primary-color: #FF6E30;