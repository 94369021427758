.ant-space-item:first-child {
  flex: 1;
}

.question_card_container {
  text-align: left;
}

.ant-card-actions {
  border-radius: 0 0 10px 10px;
  padding: 12px 0px;
}

.ant-card-head-title {
  padding: 24px 0px;
}
